.accordion-item {
  position: relative;
  margin-bottom: 4rem;

  @media ($tablet) {
    margin-bottom: 6rem;
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  .accordion-header {
    position: relative;
    display: block;
    color: $gray;
    font-size: 2rem;
    font-weight: 400;
    line-height: 1.4;
    cursor: pointer;
    //transition: color 0.2s ease-in-out;
    transition: all .2s cubic-bezier(.65, .05, .36, 1);

    @media ($mobile-narrow) {
      font-size: 2.6rem;
    }

    @media ($tablet) {
      font-size: 3.6rem;
    }

    &:before {
      content: '';
      width: 0;
      height: 0.3rem;
      background-color: $pink;
      position: absolute;
      top: 1.75rem;
      left: -7rem;
      transition: all .2s cubic-bezier(.65, .05, .36, 1);
    }

    &:after {
      @include arrow($pink);
      cursor: pointer;
      top: 1.5rem;
      right: -2rem;
      transform: rotate(-135deg);

      @media ($mobile-narrow) {
        top: 2rem;
      }

      @media ($tablet-wide) {
        display: none;
      }
    }

    &:not(.is-active) {
      &:hover {
        color: darken($gray, 15%);
      }

      &:active {
        color: darken($gray, 20%);
      }
    }
  }

  .accordion-content {
    display: none;
    color: $gray;
    opacity: 0;
    transition: opacity .3s linear .18s;
  }

  &.is-active {
    @media ($tablet-wide) {
      padding-left: 7rem;
    }

    .accordion-header {
      color: $black;
      font-weight: 700;

      &:before {
        @media ($tablet-wide) {
          //display: block;
          width: 4.4rem;
          transition: width .35s cubic-bezier(.65, .05, .36, 1);
        }
      }

      &:after {
        top: 0.75rem;
        transform: rotate(-45deg);

        @media ($mobile-narrow) {
          top: 1.25rem;
        }
      }
    }

    .accordion-content {
      display: block;
      opacity: 1;
    }
  }
}
