ul,
ol {
  padding: 0;
  margin: 0 0 1rem 2rem;

  li {
    margin-left: 0;

    p {
      margin-bottom: 0;
    }
  }
}

.list {
  margin: 0 0 0 1.5rem;

  > li {
    position: relative;
    padding-left: 0.5rem;
    margin-bottom: 1rem;

    &:not(.has-no-marker):before {
      content: '';
      width: 1.2rem;
      height: 2px;
      background-color: $gray;
      position: absolute;
      left: -1.5rem;
      top: 1rem;
    }
  }
}

.is-pricing-header {
  background: $gradient-peach;
  border-radius: 20px;
  padding: 2rem 0;
  text-align: center;

  p {
    .price {
      color: $white;
      line-height: 1;
      display: block;
      text-align: center;
      margin: 0;
      font-weight: 600;
      font-size: 5rem;

      @media ($tablet) {
        font-size: 8rem;
      }

      small {
        font-size: 1.4rem;
        font-weight: normal;
      }
    }

    small {
      color: $white;
      font-size: 1.5rem;
      font-weight: bold;

      &.normally {
        font-size: 1.3rem;
        opacity: 0.8;
      }
    }
  }
}

.is-pricing-sub-header {
  font-weight: bold;
  font-size: 2.4rem;
  margin-top: 3rem;
  text-align: center;
}

.is-pricing-list {
  list-style: none;
  margin: 0 0 2rem;
  font-size: 1.4rem;

  li {
    display: flex;
    align-items: center;
    margin-bottom: 1.25rem;

    .is-col {
      background: #f8f8f8;
      border: 1px solid #eeeeee;
      border-radius: 20px;
      justify-content: center;
      align-content: center;
      padding: 1.5rem;

      h4 {
        font-size: 1.8rem;
        text-align: center;
        margin: 0;
      }

      p {
        text-align: center;
        margin: 0;
      }
    }
  }
}

.is-icon-list {
  list-style: none;
  margin: 0 0 2rem;
  font-size: 1.4rem;

  li {
    display: flex;
    align-items: center;
    margin-bottom: 1.25rem;

    .is-col {
      justify-content: center;

      &.icon-container {
        flex: 0 0 $icon-size - 1.5rem;
        margin: 0 1rem 0 0;
        padding-left: 0;

        @media ($tablet) {
          margin: 0;
        }

        .icon,
        .icon > img {
          height: $icon-size - 1.5rem;
          width: $icon-size - 1.5rem;

          @media ($mobile-narrow) {
            height: $icon-size;
            width: $icon-size;
          }
        }
      }
    }

    p {
      margin: 0;
    }
  }

  &.is-home {
    li {
      margin-bottom: 4.25rem;

      .is-col {
        &.icon-container {
          .icon,
          .icon > img {
            height: 7.5rem;
            width: 7.5rem;
          }
        }
      }

      p {
        margin: 0;
        font-size: 1.7rem;
      }
    }
  }

  &.is-boxed {
    background: $white;
    border-radius: $radius;
    box-shadow: $box-shadow;
    margin: 2rem 0;

    @media ($tablet-wide) {
      margin: 0;
    }

    li {
      margin-bottom: 0;
      border-bottom: 1px solid $border-color;

      &.list-header {
        padding: 2rem;
        font-weight: 600;
        font-size: 2rem;
      }

      &:last-of-type {
        border-bottom: 0;
        border-bottom-right-radius: $radius;
        border-bottom-left-radius: $radius;

        > a, button {
          border-bottom-right-radius: $radius;
          border-bottom-left-radius: $radius;
        }
      }

      button {
        border: 0;
        font-family: inherit;
        font-size: inherit;
        text-align: left;
        align-items: center;

        &:disabled,
        &[disabled]{
          opacity: 30%;
          cursor:not-allowed !important;
        }
      }

      a:not(.is-text-link), button {
        position: relative;
        color: $black;
        font-weight: 600;
        padding: 1rem 2rem;
        background-color: transparent;
        transition: all 0.2s ease-in-out;

        &:after {
          @include arrow($gray);
          width: 1.5rem;
          height: 1.5rem;
          cursor: pointer;
          right: 2rem;
          transform: rotate(-135deg);
        }

        &:hover,
        &:focus {
          cursor: pointer;
          color: darken($green, 10%) !important;
          background-color: rgba($black, 0.05);

          &:after {
            border-color: darken($green, 0.1) !important;
          }
        }

        &:active {
          color: darken($green, 15%);

          &:after {
            border-color: darken($green, 15%) !important;
          }
        }
      }
    }
  }
}

ol.is-numbered-list {
  list-style: none;
  counter-reset: item;
  margin: 0 0 2rem;
  font-size: 1.4rem;

  li {
    counter-increment: item;
    display: flex;
    align-items: center;
    margin-bottom: 1.25rem;

    &:before {
      content: counter(item);
      display: inline-block;
      width: 3rem;
      height: 3rem;
      margin-right: 1rem;
      padding: 0.25rem 1rem;
      border: 1px solid $gray;
      border-radius: $radius-rounded;
      text-align: center;
      color: $black;
      background-color: rgba($gray, 0.5);
    }
  }

  &.is-green li:before {
    color: $green;
    border-color: $green;
    background-color: rgba($green, 0.1);
  }

  &.is-gradient-peach li:before {
    color: $pink;
    border: solid 1px transparent;
    background-origin: border-box;
    background-clip: content-box, border-box;
    box-shadow: 1px 1000px 1px $white inset;
    background-image: linear-gradient(rgba($white, 0), rgba($white, 0)), $gradient-peach;
  }
}
