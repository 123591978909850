::placeholder {
  color: $gray;
  font-family: $body-font;
  font-weight: 400;

  @media ($tablet) {
    font-size: 1.2rem;
  }
}

form,
.form {
  .is-row {
    align-items: baseline;
  }
}

.input {
  @include input-textarea-control;
  font-weight: 500;

  &.is-small {
    height: 4rem;
    border-radius: 1rem;
    font-size: 14px;
  }
}

.textarea {
  @include input-textarea-control;
  display: block;
  max-width: 100%;
  min-width: 100%;
  padding: calc(1.5em - 1px);
  resize: vertical;
  font-weight: 500;

  &:not([rows]) {
    max-height: 40em;
    min-height: 8em;
  }

  &[rows] {
    height: initial;
  }

  &.has-fixed-size {
    resize: none;
  }
}

label:not(.checkbox):not(.radio),
.label:not(.checkbox):not(.radio) {
  display: block;
  color: $black;
  font-size: 16px;
  font-weight: 600;

  &:not(:last-child) {
    margin-bottom: 0.25em;
  }

  @media ($tablet) {
    font-size: 1.8rem;
  }

  &.no-margin {
    margin-bottom: 0;
  }

  .required {
    position: relative;
    top: -4px;
    left: -4px;
    color: $pink;
    font-weight: 600;
    font-size: 20px;
  }
}

.form-section-label {
  color: $black;
  font-size: 1.8rem;
  line-height: 1.6;
  margin: 3rem 0 1rem !important;
}

.form-item {
  position: relative;
  margin: 0 0 1rem;
  border-top: none;

  @media ($tablet) {
    margin-bottom: 1.5rem;
  }

  &.is-fullwidth-tablet {
    @media ($under-tablet-wide) {
      flex: 100%;
    }
  }

  &.is-fullwidth-tablet-wide {
    @media ($under-desktop) {
      flex: 100%;
    }
  }

  table {
    width: 100%;
  }

  &.has-icon-left,
  &.has-icon-right {
    .icon {
      position: absolute;
      width: 2rem;
      height: 2rem;
      top: 1rem;
      z-index: 1;
    }

    .svg-icon {
      top: 36%;
    }
  }

  &.has-icon-left {
    .icon {
      left: 2.75rem;
    }

    .input {
      padding-left: 5rem;
    }
  }

  &.has-icon-right {
    .icon {
      right: 2.75rem;
    }

    .input {
      padding-right: 5rem;
    }
  }

  &.has-input-button {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;

    // div.input (not actual input field)
    > .input {
      padding-left: 0;
      padding-right: 0;
      cursor: default;
      background-color: $white;
      box-shadow: $box-shadow;

      &:focus,
      &:active {
        border-color: $border-color;
      }

      > .is-row:first-of-type > [class^='is-col'] {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
      }

      // Actual <input class="input"/>
      input.input {
        background: transparent;
        border-color: transparent;
        margin: 0;
        box-shadow: none;
        font-size: 1.2rem;

        @media ($mobile-narrow) {
          font-size: 1.4rem;
        }

        &.home {
          background-color: $white;
          padding: 0 8px;
        }
      }
    }

    .static-text {
      margin-right: 1rem;
      text-align: right;
      font-size: 1.2rem;
      font-weight: 600;

      @media ($mobile-narrow) {
        font-size: 1.4rem;
      }
    }

    .button,
    + .button {
      height: 5rem; // important to fit in input
      border-radius: $radius - 4px;
      margin: 0 0 0 auto;
      font-size: 1.4rem;
      padding-left: 1em;
      padding-right: 1em;

      &.is-home {
        min-width: 11em;
      }
    }
  }

  > svg {
    margin: 0;
    position: absolute;
    top: 50%;
    right: 1.2em;
    transform: translateY(-50%);
  }
}

.help {
  font-size: 1.4rem;
  margin: 0.5rem 0 0 !important;
}

// Stripe Credit Card Input
.form-credit-card {
  background-color: lighten($border-color, 30%);
  border: 1px solid darken($border-color, 10%);
  border-radius: $radius;
  color: $black;
  padding: 18px 20px;

  &.StripeElement--focus,
  &.StripeElement--complete {
    background-color: $white;
    border-color: $black;
  }

  &.StripeElement--invalid {
    background-color: $white;
    border-color: $pink;
  }
}

// sign up promotion
.signup-promotion {
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    max-width: 30px;
    margin: 0 8px 0 0;
  }

  p {
    font-size: 1.2rem;
    text-align: center;

    @media ($tablet) {
      font-size: 1.4rem;
      text-align: left;
      white-space: nowrap;
    }
  }
}

.privacy-terms-label {
  font-size: 12px !important;
  text-align: center !important;
  justify-content: center !important;
  display: flex !important;

  input {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }
}
