@mixin base-control {
  -moz-appearance: none;
  -webkit-appearance: none;
  align-items: center;
  border: 1px solid transparent;
  border-radius: $radius;
  box-shadow: none;
  display: inline-flex;
  font-family: $body-font;
  font-size: 1.4rem;
  height: 6rem;
  justify-content: flex-start;
  line-height: 1.5;
  padding-bottom: calc(0.5em - 1px);
  padding-left: calc(1.5em - 1px);
  padding-right: calc(1.5em - 1px);
  padding-top: calc(0.5em - 1px);
  position: relative;
  vertical-align: top;

  @media ($tablet) {
    font-size: 1.4rem;
  }

  // States
  &:focus,
  &:active {
    outline: none;
  }

  &.is-disabled,
  &[disabled],
  fieldset[disabled] & {
    cursor: not-allowed;
  }
}

@mixin input-control {
  @include base-control;
  background-color: lighten($border-color, 30%);
  border-color: darken($border-color, 10%);
  border-radius: $radius;
  color: $black;
  transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;

  //&:placeholder-shown {
  //  background-color: lighten($border-color, 20%);
  //  border-color: darken($border-color, 10%);
  //}

  &:focus {
    background-color: $white;
    border-color: $black;
  }

  &:not(:placeholder-shown) {
    background: #FFFFFF;
    border-color: $black;
  }

  &.is-disabled,
  &[disabled],
  &[readonly],
  &[readOnly],
  fieldset[disabled] & {
    background-color: rgba($gray, 0.3);
    border-color: darken($border-color, 20%);
    box-shadow: none;
    color: darken($gray, 20%);
    -webkit-text-fill-color: darken($gray, 20%);
    opacity: 1; /* required on iOS */
    cursor: not-allowed;
  }

  .form-item.has-error &,
  &.has-error {
    border-color: $pink;
    background-color: rgba($pink, 0.1);
  }
}

@mixin input-textarea-control {
  @include input-control;
  box-shadow: inset 0 0.0625em 0.125em rgba(#000000, 0.05);
  max-width: 100%;
  width: 100%;

  &.is-fullwidth {
    display: block;
    width: 100%;
  }

  &.is-inline {
    display: inline;
    width: auto;
  }

  &.is-readonly,
  &[readonly] {
    box-shadow: none;
  }
}

@mixin unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@mixin delete {
  @include unselectable;
  -moz-appearance: none;
  -webkit-appearance: none;
  background-color: rgba(#000000, 0.2);
  border: none;
  border-radius: $radius-rounded;
  cursor: pointer;
  pointer-events: auto;
  display: inline-block;
  flex-grow: 0;
  flex-shrink: 0;
  font-size: 0;
  height: 20px;
  max-height: 20px;
  max-width: 20px;
  min-height: 20px;
  min-width: 20px;
  outline: none;
  position: relative;
  vertical-align: top;
  width: 20px;
  transition: background-color 0.2s ease-in-out;

  &::before,
  &::after {
    background-color: $white;
    content: '';
    display: block;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translateX(-50%) translateY(-50%) rotate(45deg);
    transform-origin: center center;
  }

  &::before {
    height: 2px;
    width: 50%;
  }

  &::after {
    height: 50%;
    width: 2px;
  }

  &:hover,
  &:focus {
    background-color: rgba(#000000, 0.3);
  }

  &:active {
    background-color: rgba(#000000, 0.4);
  }
}

@mixin overlay {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

@mixin arrow($color: transparent) {
  border: 2px solid $color;
  border-radius: 2px;
  border-right: 0;
  border-top: 0;
  content: ' ';
  display: block;
  width: 2rem;
  height: 2rem;
  margin-top: -0.4375em;
  pointer-events: none;
  position: absolute;
  top: 50%;
  transform: rotate(-45deg);
  transform-origin: center;
}

@mixin loader($color: #ffffff) {
  animation: spinAround 500ms infinite linear;
  border: 2px solid $color;
  border-radius: $radius-rounded;
  border-right-color: transparent;
  border-top-color: transparent;
  content: '';
  display: block;
  height: 1em;
  position: relative;
  width: 1em;
}

@keyframes spinAround {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

@mixin center($width, $height: 0) {
  position: absolute;
  @if $height != 0 {
    left: calc(50% - (#{$width} / 2));
    top: calc(50% - (#{$height} / 2));
  } @else {
    left: calc(50% - (#{$width} / 2));
    top: calc(50% - (#{$width} / 1.5));
  }
}

@mixin on-mobile() {
  @media only screen and (max-width: $max-width) {
    @content;
  }
}
