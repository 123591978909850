.box {
  position: relative;
  background: $white;
  border-radius: $radius;
  box-shadow: $box-shadow;
  color: $gray;
  display: block;
  margin-bottom: 1rem;
  padding: 2rem;

  @media ($mobile-narrow) {
    margin-bottom: 4rem;
  }

  @media ($tablet-wide) {
    padding: 2.5rem;
    border-radius: $radius-medium;
    margin-bottom: 0;
  }

  @media ($desktop) {
    padding: 3rem;
  }

  &.is-large {
    padding: 2rem ($gap / 2);

    @media ($mobile-narrow) {
      padding: 2.5rem 2rem;
    }

    @media ($tablet) {
      padding: 4rem 3.5rem;
    }

    @media ($tablet-wide) {
      padding: 4rem;
    }

    @media ($desktop) {
      padding: 6rem;
    }
  }

  &.is-transparent {
    color: $black;
    background: transparent;
    box-shadow: none;
    // test
    .box.is-large & {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &.is-green {
    color: $green;
    background-color: rgba($green, 0.08);
    box-shadow: none;
  }

  &.is-gray {
    background-color: rgba($gray, 0.045);
    border: 1px solid rgba($gray, 0.25);
    box-shadow: none;
  }

  // background color/gradients
  &.has-gradient-dark {
    background: $gradient-dark;
    border-radius: $radius-medium;
    box-shadow: none;
    margin-bottom: 0;
  }

  &.has-border {
    border: 1px solid $border-color;
  }

  // .box inside another .box should have smaller radius than its parent
  .box {
    border-radius: $radius;
  }

  // radius size
  &.has-radius {
    &-small {
      border-radius: $radius !important;
    }

    &-medium {
      border-radius: $radius-medium !important;
    }

    &-large {
      border-radius: $radius-large !important;
    }
  }

  .box + & {
    margin-top: 1.5rem;
  }

  p + &,
  .content + & {
    margin-top: 1.5rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    &:first-child {
      margin-top: 0;
    }

    margin-top: 1.5rem;

    p + & {
      margin-top: 0;
    }
  }

  &.has-background-circles {
    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-image: url('img/box-circle-background.png');
      background-repeat: no-repeat;
      background-position: -20rem 44rem;
      background-size: 300%;

      @media ($mobile-narrow) {
        background-size: 150%;
        background-position: 7rem 4rem;
      }

      @media ($tablet) {
        background-size: 100%;
        background-position: 12rem 4rem;
      }

      @media ($tablet-wide) {
        background-position: -2rem 2rem;
      }

      @media ($desktop) {
        background-position: -6rem 4rem;
      }
    }

    &.background-circles-bottom:before {
      background-position: -20rem 44rem;
      background-size: 300%;

      @media ($mobile-narrow) {
        background-size: 150%;
        background-position: 0rem 1rem;
      }

      @media ($tablet) {
        background-size: 125%;
        background-position: 20rem 5rem;
      }

      @media ($tablet-wide) {
        background-size: 100%;
        background-position: 23rem 47rem;
      }
    }

    &.background-circles-bottom-left:before {
      background-position: -20rem 44rem;
      background-size: 300%;

      @media ($mobile-narrow) {
        background-size: 150%;
        background-position: 0rem 1rem;
      }

      @media ($tablet) {
        background-size: 125%;
        background-position: 20rem 5rem;
      }

      @media ($tablet-wide) {
        background-size: 100%;
        background-position: -20rem 44rem;
      }
    }
  }

  h2.how-it-works {
    br {
      @media ($mobile-narrow) {
        display: none;
      }
    }
  }
}
