* {
  box-sizing: border-box;
}

::selection {
  color: $black;
  background: rgba($green, 0.2); // change as needed
  text-shadow: none;
}

html {
  background-color: $white;
  font-size: 10px; // with this set to 10px, "rem" units to pixels = 10px * {rem}. Example: 1.6rem = 16px = 10 * 1.6
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  min-width: 300px;
  overflow-x: hidden;
  overflow-y: scroll;
  text-rendering: optimizeLegibility;
  text-size-adjust: 100%;
  scroll-behavior: smooth;

  &.is-clipped {
    overflow: hidden !important;
  }
}

body {
  color: $black;
  background: $white;
  font-family: $body-font;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.5;

  &.is-clipped {
    overflow: hidden !important;
  }
}

a {
  color: $green;
  text-decoration: none;
  transition: color 0.2s ease-in-out, border-color 0.2s ease-in-out;

  &:not(.button) {
    font-weight: 600;
  }

  &:hover,
  &:focus {
    color: darken($green, 10%);
  }

  &:active {
    color: darken($green, 15%);
  }
}

p {
  margin: 0 0 2rem;
  word-wrap: break-word; // if a word is longer than an entire line, break it to the next line (often true with email addresses on phones) - otherwise won't affect anything

  &:last-child {
    margin-bottom: 0;
  }

  small {
    font-size: 80%;
  }
}

b,
strong {
  color: $black;
  font-weight: 600;

  p & {
    font-weight: 600;
  }
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid $gray;
  margin: 1em auto;
  padding: 0;
}

img {
  max-width: 100%;
  height: auto;
}

.image:not(.icon) {
  display: block;

  img:not(.icon) {
    display: block;
    height: auto;
    width: 100%;

    &.is-rounded {
      border-radius: $radius-rounded;
    }
  }

  &.is-rounded img {
    border-radius: $radius-rounded;
  }
}

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

address {
  font: inherit;
  padding: 0;
}

iframe {
  width: 100%;
  max-width: 100%;
}

.delete {
  @include delete;
}

.icon {
  align-items: center;
  display: inline-flex;
  justify-content: center;
  height: $icon-size;
  width: $icon-size;

  > img {
    display: block;
    max-width: 100%;
    height: $icon-size;
    width: $icon-size;
    margin: auto !important;
  }
}

main {
  width: 100%;

  @media ($tablet) {
    padding: 0 2rem;
  }
}

.page-container {
  position: relative;
  width: 100%;
  max-width: $content-max-width;
  margin: 0 auto;
  padding: 1rem ($gap / 2);

  @media ($mobile-narrow) {
    padding: 3rem ($gap / 2);
  }

  @media ($tablet) {
    padding: 3rem 0;
  }

  img {
    display: block;
    margin: 0 auto 2rem;
  }
}

blockquote,
blockquote.box {
  font-weight: 600;
  padding-left: 5rem;

  @media ($tablet) {
    padding-left: 10rem;
  }

  *:first-child {
    position: relative;

    &:before {
      content: '';
      background-image: url('img/icon-quote.svg');
      background-repeat: no-repeat;
      background-size: 30px 15px;
      width: 30px;
      height: 15px;
      position: absolute;
      left: -4rem;
      top: 0.5rem;

      @media ($tablet) {
        background-size: 40px 20px;
        width: 40px;
        height: 20px;
        left: -6rem;
      }
    }
  }
}
