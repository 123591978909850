// Select dropdown
// Usage:
// <div class="select is-fullwidth">
//   <select id="the-id" name="the-name">
//     <option value="" disabled selected>Select an option</option>
//     <option value="1">First option</option>
//     <option value="2">Second option</option>
//   </select>
// </div>

.select {
  max-width: 100%;
  position: relative;
  vertical-align: top;

  &:not(.is-multiple):not(.is-loading) {
    &::after {
      @include arrow($gray);
      width: 1.25rem;
      height: 1.25rem;
      top: 2.75rem;
      right: 1.125em;
      z-index: 4;
    }
  }

  &.is-rounded {
    select {
      border-radius: $radius-rounded;
      padding-left: 1em;
    }
  }

  + .help {
    margin-top: 0.5rem;
  }

  &.has-error {
    select {
      border-color: $pink !important;
      background-color: rgba($pink, 0.1) !important;
    }

    &::after {
      border-color: $pink !important;
    }
  }

  select {
    @include input-control;
    color: $gray;
    cursor: pointer;
    display: block;
    font-family: $body-font;
    font-size: 1em;
    max-width: 100%;
    outline: none;
    background-color: lighten($border-color, 20%) !important;
    border-color: $border-color !important;

    &::-ms-expand {
      display: none;
    }

    &[disabled]:hover {
      border-color: $border-color;
    }

    &:not([multiple]) {
      padding-right: 2.5em;
    }

    &:valid {
      color: $black;
      background-color: $white !important;
      border-color: $green !important;
      font-weight: 500;
    }

    &[multiple] {
      height: auto;
      padding: 0;

      option {
        padding: 0.5em 1em;
      }
    }

    option {
      color: $gray;
      font-size: 1.6rem;

      &[disabled] {
        font-weight: 400;
      }

      &:checked {
        color: $green;
        font-weight: 600;
      }
    }
  }

  // States
  &:not(.is-multiple):not(.is-loading):hover {
    &::after {
      transition: border-color 0.2s ease-in-out;
      border-color: darken($gray, 10%);
    }
  }

  // rotate arrow
  &:not(.is-multiple):not(.is-loading).is-active {
    &::after {
      transform: rotate(135deg);
      top: 60%;
    }
  }

  // Colors
  @each $name, $pair in $button-colors {
    $color: nth($pair, 1);
    &.is-#{$name} {
      &:not(:hover)::after {
        border-color: $color;
      }

      select {
        border-color: $color;
        &:hover {
          border-color: darken($color, 5%);
        }

        &:focus,
        &:active,
        &.is-active {
          box-shadow: 0 0 0 0.125em rgba($color, 0.25);
        }
      }
    }
  }

  // Modifiers
  &.is-disabled {
    &::after {
      border-color: $border-color;
    }
  }

  &.is-fullwidth {
    width: 100%;

    select {
      width: 100%;
    }
  }
}
