h1,
h2,
h3,
h4,
h5,
h6 {
  color: $black;
  font-family: $header-font;
  font-weight: 600;
  font-style: normal;
  line-height: 1.2;
  margin: 1rem 0 2rem;

  b,
  strong {
    font-weight: 700 !important;
  }

  a {
    border: 0 !important;
  }
}

h1 {
  margin: 0 0 2.5rem;
  font-weight: 700;
  font-size: 2.2rem;

  @media (max-width: 320px) {
    font-size: 2rem;
  }

  @media ($mobile-narrow) {
    font-size: 3.0rem;
  }

  &.is-large {
    font-size: 4rem;

    @media ($tablet) {
      font-size: 5rem;
    }
  }
}

h2 {
  margin: 0 0 2.5rem;
  font-weight: 700;
  font-size: 2.5rem;

  @media (max-width: 320px) {
    font-size: 2rem;
  }

  @media ($mobile-narrow) {
    font-size: 3.6rem;
  }
}

h3 {
  line-height: 1.4;
  font-size: 3.6rem;

  @media ($tablet) {
    font-size: 3rem;
  }
}

h4 {
  font-size: 2.4rem;

  @media ($tablet) {
    font-size: 2.8rem;
  }
}

h5 {
  font-size: 2.2rem;

  @media ($tablet) {
    font-size: 2.4rem;
  }
}

h6 {
  font-size: 2rem;
}
