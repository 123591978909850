#overlay-button {
  position: absolute;
  right: 0;
  top: 0;
  padding: 16px 8px;
  z-index: 600;
  cursor: pointer;
  user-select: none;
  span {
    height: 3px;
    width: 25px;
    border-radius: 2px;
    background-color: $black;
    position: relative;
    display: block;
    transition: all .2s ease-in-out;
    &:before {
      top: -7px;
      visibility: visible;
    }
    &:after {
      top: 7px;
    }
    &:before, &:after {
      height: 3px;
      width: 25px;
      border-radius: 2px;
      background-color: $black;
      position: absolute;
      content: "";
      transition: all .2s ease-in-out;
    }
  }
  &:hover span, &:hover span:before, &:hover span:after {
    background: $gray;
  }

  @media ($tablet) {
    display: none;
  }
}

input#overlay-input {
  display: none;
}

input#overlay-input:checked ~ #overlay {
  //visibility: visible;
  height: 100vh;
}

input#overlay-input:checked ~ #overlay-button {
  &:hover span, span{
    background: transparent;
  }
  span {
    &:before {
      transform: rotate(45deg) translate(3px, 7px);
      opacity: 1;
    }
    &:after {
      transform: rotate(-45deg) translate(3px, -7px);
    }
  }
}

#overlay {
  display: block;
  height: 0;
  width: 100vw;
  background: $white;
  z-index: 400;
  position: fixed;
  overflow: hidden;
  transition: all .2s ease-in-out;

  ul {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    height: 100vh;
    padding-left: 0;
    padding-top: 70px;
    margin-left: 10px;
    margin-right: 10px;
    list-style-type: none;

    p {
      font-weight: 500;
      font-size: 10px;
      color: rgba(51,51,51,0.50);
      text-transform: uppercase;
      margin-bottom: 0;
    }

    hr {
      border-top: 1px solid rgba(51,51,51,0.30);
      width: 100vw;
    }

    li {
      //padding: 1em;
      margin-left: 10px;
      button {
        border: 0;
        background: none;
        cursor: pointer;
        color: $georgiaPlacesBlack;
        text-decoration: none;
        font-size: 18px;
        font-weight: 500;
        line-height: 36px;
        &:hover {
          color: $gray;
        }
      }
    }
  }

  &.active {
    height: 100vh;
  }

  @media ($tablet) {
    display: none;
  }
}

.tab-bar {
  height: 36px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.35);
  display: flex;
  align-items: center;
  position: relative;
  z-index: 500;

  figure {
    width: 100px;
    margin-left: 10px;
    cursor: pointer;
    z-index: 999;

    @media ($desktop) {
      width: 120px;
    }
  }

  nav {
    display: none;
    position: absolute;
    width: 100%;
    height: 36px;

    ul {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      margin: 0;
      padding: 0;

      li {
        margin: 0 12px 0 12px;
        height: 100%;

        a {
          color: rgba(51,51,51,0.40);
          text-decoration: none;
          font-size: 12px;
          font-weight: 500;
          line-height: 19.5px;

          &:hover {
            color: $gray;
          }

          &.is-active {
            color: $georgiaPlacesBlack;
          }

          span {
            margin-top: 10px;
            display: block;
          }
        }

        &.is-active {
          border-bottom: 3px solid #333333;
        }
      }
    }

    @media ($tablet) {
      display: block;
    }
  }

  @media ($tablet) {
    display: none;
  }
}
