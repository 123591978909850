// Base
@import './variables';
@import './mixins';
@import './reset';

// Helpers
@import './helpers';
@import './spacing';

// Base
@import './base';
@import './lists';
@import './tab-bar';
@import './header';
@import './footer';
@import './header-styles';
@import './buttons';
@import './box';
@import './modal';
@import './accordion';

// Forms
@import './forms';
@import './select';
@import './radios-and-checkboxes';

// Pages
@import './page-home';
@import './page-blog-post';

// Extra
@import './print';

@media screen and (max-width: 415px) {
  input[type="text"],
  input[type="search"],
  input[type="number"],
  input[type="email"],
  input[type="tel"],
  input[type="password"],
  select:focus {
    font-size: 16px !important;
  }
}
