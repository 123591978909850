.is-row {
  display: flex;
  flex-flow: row wrap;
  width: 100%;

  @media ($tablet) {
    .box & {
      width: calc(100% + 1rem);
    }
    // Add spacing between columns on tablet-wide or larger
    // by adding .has-column-spacing to the '.is-row' element
    &.has-column-spacing {
      margin: 0 -1rem;
    }
  }

  &.is-aligned-center {
    align-items: center;
  }

  &.is-aligned-baseline {
    align-items: baseline;
  }

  .radio &,
  .checkbox & {
    align-items: center !important;
  }
}

// Columns
.is-col,
.is-col-1,
.is-col-2,
.is-col-3 {
  display: flex;
  flex-flow: column wrap;
  flex-basis: 100%;

  &.is-justified-center {
    justify-content: center;
  }

  .has-column-spacing & {
    @media ($tablet) {
      // Add spacing between columns on tablet-wide or larger
      // by adding .has-column-spacing to the '.is-row' element
      padding: 0 1rem;
    }
  }

  // Columns only active on tablet-wide and above
  @media ($tablet-wide) {
    flex: 1;
  }

  // to enable column on mobile
  &.is-mobile {
    flex: 1;
  }
}

.is-col-2 {
  // Columns only active on tablet-wide and above
  @media ($tablet-wide) {
    flex: 2;
  }

  // to enable column on mobile
  &.is-mobile {
    flex: 2;
  }
}

.is-col-3 {
  // Columns only active on tablet-wide and above
  @media ($tablet-wide) {
    flex: 3;
  }

  // to enable column on mobile
  &.is-mobile {
    flex: 3;
  }
}

.is-hidden {
  display: none !important;
}

.is-hidden {
  &-mobile {
    @media ($under-tablet) {
      display: none !important;
    }
  }
  &-tablet {
    @media ($tablet) and ($under-tablet-wide) {
      display: none !important;
    }
  }
  &-tablet-wide {
    @media ($tablet-wide) and ($under-desktop) {
      display: none !important;
    }
  }
  &-desktop {
    @media ($desktop) {
      display: none !important;
    }
  }
}

.is-transparent {
  background: transparent !important;
}

.is-marginless {
  margin: 0 !important;

  &-mobile {
    @media ($under-tablet-wide) {
      margin: 0 !important;
    }
  }
}

.is-paddingless {
  padding: 0 !important;

  &-mobile {
    @media ($under-tablet-wide) {
      padding: 0 !important;
    }
  }
}

.is-borderless {
  border: 0 !important;
}

.is-unselectable {
  @include unselectable;
}

// Cycle through text colors
@each $name, $color in $text-colors {
  .has-text-#{$name} {
    color: $color !important;
  }
  .has-background-#{$name} {
    background-color: $color !important;
  }
  a.has-text-#{$name} {
    &:hover,
    &:focus {
      color: darken($color, 10%) !important;
    }

    &:active {
      color: darken($color, 15%);
    }
  }
}

.has-text-nowrap {
  white-space: nowrap;
}

.has-text-uppercase {
  text-transform: uppercase;
}

.has-text-centered {
  text-align: center !important;
}

.has-text-left {
  text-align: left !important;
}

.has-text-right {
  text-align: right !important;
}

.has-text-emphasized {
  color: $black;
  font-weight: 600;
  line-height: 1.3;
  font-size: 1.8rem;

  @media ($tablet) and ($under-tablet-wide) {
    font-size: 1.7rem;
  }

  @media ($under-mobile-narrow) {
    font-size: 1.5rem;
  }
}

.has-text-gradient-peach {
  background: $gradient-peach;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  -webkit-box-decoration-break: clone;
}

.is-text-link {
  position: relative;
  padding-left: 2.25rem;

  &:before {
    content: '';
    width: 1.5rem;
    height: 1.5rem;
    position: absolute;
    left: 0;
    top: 0.5rem;
    background-color: transparent;
    border: 1px solid $green;
    border-radius: $radius-rounded;
  }

  // Must include span tag for "dot"
  > span:before {
    content: '';
    width: 3px;
    height: 3px;
    background-color: $green;
    border-radius: $radius-rounded;
    position: absolute;
    left: 0.6rem;
    top: 1.1rem;
  }

  // Inside of a .list
  .list & {
    padding-left: 0.5rem;

    &:before {
      left: -2.25rem;
    }

    > span:before {
      left: -1.65rem;
    }
  }

  &:hover,
  &:focus {
    color: darken($green, 10%);

    > span:before {
      background-color: darken($green, 10%);
    }
  }

  &:active {
    color: darken($green, 15%);

    > span:before {
      background-color: darken($green, 15%);
    }
  }

  // Colors
  @each $name, $color in $text-colors {
    &.has-text-#{$name} {
      &:before {
        border-color: $color !important;
      }
      > span:before {
        background-color: $color !important;
      }

      &:hover,
      &:focus {
        color: darken($color, 10%);
        &:before {
          border-color: darken($color, 10%) !important;
        }
      }

      &:active {
        color: darken($color, 10%) !important;
      }
    }
  }
}

.is-faqs-link {
  text-decoration: underline;

  &:hover,
  &:focus {
    color: darken($green, 10%);

    > span:before {
      background-color: darken($green, 10%);
    }
  }

  &:active {
    color: darken($green, 15%);

    > span:before {
      background-color: darken($green, 15%);
    }
  }
}

.is-circle-number {
  position: relative;
  display: inline-block;
  width: 3rem;
  height: 3rem;
  margin-right: 1rem;
  padding: 0.25rem 1rem;
  border: 1px solid $gray;
  border-radius: $radius-rounded;
  text-align: center;
  font-weight: 400;
  font-size: 1.5rem;
  color: $black;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: $radius-rounded;
    background-color: rgba($gray, 0.5);
  }

  h1 &,
  h2 &,
  h3 &,
  h4 & {
    top: -3px;
    line-height: 1.6;
  }

  &.is-green {
    color: $green;
    border-color: $green;

    &:before {
      background-color: rgba($green, 0.1);
    }
  }

  &.is-gradient-peach {
    color: $pink;
    border: solid 1px transparent;
    background-origin: border-box;
    background-clip: content-box, border-box;
    box-shadow: 1px 1000px 1px $white inset;
    background-color: transparent;
    background-image: linear-gradient(rgba($white, 0), rgba($white, 0)), $gradient-peach;

    &:before {
      background-color: transparent;
      background-color: linear-gradient(rgba($white, 0), rgba($white, 0)), $gradient-peach;
    }
  }
}

.is-sr,
.is-sr-only {
  // Text meant only for screen readers.
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  word-wrap: normal !important;
}

.is-clearfix,
.cf,
.clearfix {
  &:before,
  &:after {
    content: ' ';
    display: table;
  }
  &:after {
    clear: both;
  }
}

.is-pending-text {
  color: $pink;

  &.home-search {
    font-size: 1.3rem;
    text-align: center;
    width: 100%;
    display: block;
    margin: 8px 0 8px;
    position: relative;

    @media ($mobile-narrow) {
      margin: -8px 0 8px;
    }

    @media ($tablet-wide) {
      text-align: left;
      position: absolute;
      margin-top: -12px;
      margin-left: 24px;
      font-weight: 600;
    }
  }

  &.contact-error {
    font-size: 1.3rem;
  }
}

.is-error-text {
  color: $pink;

  &.home-search {
    font-size: 1.3rem;
    text-align: center;
    width: 100%;
    display: block;
    margin: 8px 0 8px;
    position: relative;

    @media ($mobile-narrow) {
      margin: -8px 0 8px;
    }

    @media ($tablet-wide) {
      text-align: left;
      position: absolute;
      margin-top: -12px;
      margin-left: 24px;
      font-weight: 600;
    }
  }

  &.contact-error {
    font-size: 1.3rem;
  }
}

.is-success-text {
  color: $green;

  &.home-search {
    font-size: 1.3rem;
    text-align: center;
    width: 100%;
    display: block;
    margin: 8px 0 8px;
    position: relative;

    @media ($mobile-narrow) {
      margin: -8px 0 8px;
    }

    @media ($tablet-wide) {
      text-align: left;
      position: absolute;
      margin-top: -12px;
      margin-left: 24px;
      font-weight: 600;
    }
  }
}

.disabled-link {
  pointer-events: none;
  opacity: 0.3;

  &:hover {
    cursor: not-allowed !important;
  }
}
