.header {
  position: relative;
  width: 100%;
  background: $white;

  @media ($tablet) {
    padding: 0 2rem;
  }

  .header-container {
    max-width: $content-max-width - ($gap * 4);
    margin: 0 auto;
    padding: 2rem 15px 0;
    flex-wrap: nowrap;

    @media ($mobile-narrow) {
      padding: 2rem 4rem 0;
    }

    @media ($tablet) {
      padding: 2rem 3.5rem 0;
    }

    @media ($tablet-wide) {
      padding: 2rem 4rem 0;
    }

    @media ($desktop) {
      padding: 2rem 2rem 0;
    }

    @media (min-width: 1320px) {
      padding: 2rem 0 0;
    }
  }
}

#logo-container {
  flex: 0 0 180px;
  justify-content: center;

  @media ($mobile-narrow) {
    flex: 0 0 250px;
    align-self: end;
  }

  @media ($tablet) {
    flex: 0 0 300px; // header-logo size
    align-self: end;
  }
}

.header-logo,
.footer-logo {
  display: flex;
  position: relative;
  min-width: 110px;
  border: 0;
  text-decoration: none;
  z-index: 10;
  margin-top: -0.5rem;
  align-items: center;

  @media ($mobile-narrow) {
    margin-top: -1.25rem;
  }

  @media ($tablet) {
    margin-top: 0;
  }

  .image,
  .image > img {
    display: block;
    width: 120px;
    max-width: 100%;
    height: auto;

    @media ('min-width: 350px') {
      width: 120px;
    }

    @media ($mobile-narrow) {
      width: 200px;
    }

    @media ($tablet) {
      width: 240px;
    }
  }

  p {
    font-size: 10px;
    color: $black;
    font-weight: 100;
    border-left: 1px solid $black;
    padding-left: 4px;
    margin-left: 4px;
    line-height: 1.1;

    @media ($tablet) {
      font-size: 12px;
    }
  }
}

.nav-button-container {
  padding-right: 0; // fixes is-col right alignment

  @media ($mobile-narrow) {
    padding-right: 1rem;
  }

  @media ($tablet) {
    padding-right: 1rem;
  }

  @media ($tablet-wide) {
    min-width: 320px; // change if you need a larger container for the right-side nav (e.g. if "Sign in" changes to longer text)
    flex: 0 0 320px !important; // change if you need a larger container for the right-side nav (e.g. if "Sign in" changes to longer text)
  }

  > .nav > ul {
    flex-flow: row nowrap !important;
  }

  .button {
    height: 3.5rem; // so all items are vertically aligned
    border-radius: $radius-medium;

    @media ('max-width: 375px') {
      font-size: 12px;
      padding: 8px 11px 8px 11px;
    }

    @media ($mobile-narrow) {
      height: 4.5rem;
      font-size: 1.4rem;
      padding: calc(0.5em - 1px) 1.75em;
    }

    @media ($tablet) {
      height: 5rem; // so all items are vertically aligned
    }
  }
}

.nav {
  height: 4.5rem; // so all items are vertically aligned
  padding-bottom: 0;

  &.is-aligned-center ul {
    justify-content: space-around; // fallback
    justify-content: space-evenly;
  }

  &.is-aligned-left ul {
    justify-content: flex-start;
  }

  &.is-aligned-right ul {
    justify-content: flex-end;
  }

  @media ($tablet) {
    flex-flow: row wrap;
    justify-content: flex-end;
    padding-bottom: $navbar-link-border-width;
  }

  > ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    flex-flow: column wrap;
    justify-content: flex-start;

    @media ($tablet-wide) {
      flex-flow: row wrap;
    }

    > li {
      &:last-of-type {
        > a {
          margin-right: 0;
        }

        > a.button {
          margin-left: 1.25rem;
        }
      }
    }

    a {
      &.button {
        margin: 0 2rem 0;
      }

      &:not(.button) {
        display: block;
        color: $black;
        margin: 1.5rem 1rem 0;
        padding-bottom: 3.6rem;
        text-decoration: none;
        text-align: center;
        border-bottom: $navbar-link-border-width solid transparent;
        transition: color 0.2s ease-in-out, border-color 0.2s ease-in-out;
        white-space: nowrap;
        font-size: 1.4rem;

        &:hover,
        &:active,
        &.is-active {
          border-color: $green;
        }

        &.has-no-left-margin {
          margin: 0 2rem 0 0;
        }

        &.has-no-right-margin {
          margin: 0 0 0 2rem;
        }
      }
    }

    span {
      display: block;
      color: $black;
      margin: 1.5rem 1rem 0;
      padding-bottom: 3.6rem;
      text-decoration: none;
      text-align: center;
      border-bottom: $navbar-link-border-width solid transparent;
      transition: color 0.2s ease-in-out, border-color 0.2s ease-in-out;
      white-space: nowrap;
      font-size: 1.4rem;
      font-weight: 600;
    }
  }
}

.gp-header {

  .gp-header-bg {
    height: 419px;
    background-size: cover;
    background-position: top center;

    @media ($tablet) {
      height: 512px;
    }

    @media ($desktop) {
      height: 900px;
    }

    .gp-header-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0 30px;
      padding-top: 120px;

      @media ($tablet) {
        padding-top: 140px;
      }

      @media ($desktop) {
        padding-top: 170px;
      }

      figure {
        width: 100%;

        @media ($under-tablet) {
          max-width: 420px;
        }

        @media ($tablet) {
          max-width: 420px;
        }
      }

      p {
        font-weight: 300;
        font-size: 1.05em;
        margin: 20px 0 40px;
        text-align: center;
        line-height: 1.1em;

        @media ($desktop) {
          margin: 40px 0 40px;
        }
      }

      button {
        border-radius: 30px;
      }

      > img {
        display: none;
        max-width: 46px;
        margin-top: 170px;

        @media ($desktop) {
          display: block;
        }
      }
    }
  }
}
