.button {
  @include base-control;
  height: 5rem;
  max-width: 100%;
  background-color: $black;
  border-color: $black;
  border-width: 1px;
  border-radius: $radius;
  color: $white;
  cursor: pointer;
  justify-content: center;
  margin: 0 auto 1rem;
  padding: calc(0.5em - 1px) 1.75em;
  text-align: center;
  text-decoration: none;
  font-size: 1.4rem;
  font-family: $body-font;
  font-weight: 400;
  white-space: nowrap;
  transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;

  @media ($tablet) {
    height: 6rem;
    font-size: 1.6rem;
  }

  strong {
    color: inherit;
  }

  // States
  &:focus,
  &:hover {
    color: $white;
    background-color: darken($black, 10%);
    border-color: darken($black, 10%);
  }

  &:active,
  &.is-active {
    color: $white;
    background-color: darken($black, 20%);
    border-color: darken($black, 20%);
  }

  &.is-disabled,
  &[disabled],
  fieldset[disabled] & {
    color: $white;
    background-color: $black;
    border-color: transparent;
    box-shadow: none;
    opacity: 0.5;
  }

  &.is-small {
    height: 4rem;
    border-radius: 1rem;
    font-size: 14px;
  }

  &.is-fullwidth {
    display: flex;
    width: 100%;
  }

  &.is-fullwidth-mobile {
    @media ($under-tablet) {
      display: flex;
      width: 100%;
    }
  }

  &.is-signup {
    background-image: $gradient-peach;
    border-color: $white;
    z-index: 100;

    &::before {
      position: absolute;
      content: "";
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border-radius: $radius - 4px;
      background-image: $gradient-peach-reversed;
      z-index: -1;
      transition: opacity 0.2s linear;
      opacity: 0;
    }
    &:hover {
      &::before {
        opacity: 1;
      }
    }
  }

  &.is-loading {
    color: transparent !important;
    cursor: wait;

    &::after {
      @include loader($white);
      @include center(1em);
      position: absolute;
    }
  }

  // Cycle through button colors
  @each $name, $colors in $button-colors {
    $color: nth($colors, 1);
    $background: nth($colors, 2);
    &.is-#{$name} {
      color: $color;
      background-color: $background;
      border-color: $background;

      // States
      &:focus,
      &:hover {
        color: $color;
        background-color: darken($background, 10%);
        border-color: darken($background, 10%);
      }

      &:active,
      &.is-active {
        color: $color;
        background-color: darken($background, 20%);
        border-color: darken($background, 20%);
      }

      &.is-disabled,
      &[disabled],
      fieldset[disabled] & {
        color: $color;
        background-color: $background;
        box-shadow: none;
        opacity: 0.5;
      }

      &.is-loading {
        &::after {
          border-color: transparent transparent $color $color !important;
        }
      }

      svg {
        fill: $color;
      }
    }
  }

  &.is-white {
    border-color: $black;

    &:hover {
      border-color: darken($black, 5%);
    }
  }

  &.is-gradient-peach {
    color: $pink;
    border: solid 2px transparent;
    background-origin: border-box;
    background-clip: content-box, border-box;
    box-shadow: 2px 1000px 1px $white inset;
    background-image: linear-gradient(rgba($white, 0), rgba($white, 0)), $gradient-peach;
    font-weight: 600;
    transition: all 0.2s ease-in-out;

    &:hover,
    &:focus,
    &.is-active {
      color: $white;
      background-clip: unset;
      box-shadow: none;
      background-image: linear-gradient(rgba($white, 0), rgba($white, 0)), $gradient-peach;

      .has-text-gradient-peach {
        // background: linear-gradient(215.98deg, #fff 0%, #fff 100%);
        -webkit-text-fill-color: unset;
      }
    }

    &:active,
    &.is-active {
      background-image: linear-gradient(rgba($white, 0), rgba($white, 0)), $gradient-peach-dark;

      .has-text-gradient-peach {
        // background: linear-gradient(215.98deg, #fff 0%, #fff 100%);
        -webkit-text-fill-color: unset;
      }
    }
  }

  .icon {
    &:first-child:not(:last-child) {
      margin-left: calc(-0.5em - 1px);
      margin-right: 0.25em;
    }

    &:last-child:not(:first-child) {
      margin-left: 0.25em;
      margin-right: calc(0.5em - 1px);
    }

    &:first-child:last-child {
      margin-left: calc(0.5em - 1px);
      margin-right: calc(0.5em - 1px);
    }
  }

  .icon,
  .icon > img {
    height: 1.5em !important;
    width: 1.5em !important;
  }
}
