.footer {
  position: relative;
  width: 100%;
  background: $white;

  @media ($tablet) {
    padding: 3rem 2rem 0 2rem;
  }

  .footer-container {
    max-width: $content-max-width;
    margin: 0 auto;
    padding: 2rem 2rem 0;

    @media ($tablet) {
      padding: 2rem 0 0;
    }

    .box.is-transparent {
      @media ($under-tablet-wide) {
        margin-top: 0;
        margin-bottom: 0;
        padding-top: 0;
        padding-bottom: 0;
      }
    }

    > .box {
      > .is-row {
        align-items: center;
      }

      @media ($tablet-wide) {
        &:first-of-type {
          padding-top: 1rem;
        }
      }
    }
  }

  .copyright {
    color: rgba($black, 0.8);
    font-size: 1.2rem;
    margin-top: 2rem;

    @media ($tablet-wide) {
      text-align: right;
      margin-top: 0;
    }

    small {
      opacity: 0.7;

      a {
        color: inherit;
        font-weight: inherit;
        text-decoration: underline;
      }
    }
  }
}

.footer-nav {
  height: auto;
  margin: 0;

  @media ($tablet-wide) {
    margin: 0;
  }

  > ul {
    display: flex;
    flex-flow: column wrap;
    justify-content: flex-start;
    margin: 0;

    @media ($under-tablet-wide) {
      justify-content: flex-start !important;
      align-items: flex-start;
    }

    @media ($tablet-wide) {
      flex-flow: row wrap;
    }

    > li {
      margin: 1rem 0;

      @media ($tablet-wide) {
        margin: 0;
      }
    }

    a:not(.button) {
      margin-left: 0;
      margin-right: 0;
      padding: 0;

      @media ($tablet-wide) {
        margin: 0 2rem;
      }

      &:hover,
      &:focus {
        color: darken($green, 10%);
      }

      &:active {
        color: darken($green, 15%);
      }

      &:not(.button) {
        font-size: 1.6rem;
      }
    }
  }
}
