.page-home {

  .loader,
  .loader:before,
  .loader:after {
    border-radius: 50%;
    width: 2.5em;
    height: 2.5em;
    animation-fill-mode: both;
    animation: load7 1.8s infinite ease-in-out;
  }
  .loader {
    color: $black;
    font-size: 10px;
    margin: 80px auto;
    position: relative;
    text-indent: -9999em;
    transform: translateZ(0);
    animation-delay: -0.16s;
  }
  .loader:before,
  .loader:after {
    content: '';
    position: absolute;
    top: 0;
  }
  .loader:before {
    left: -3.5em;
    animation-delay: -0.32s;
  }
  .loader:after {
    left: 3.5em;
  }
  @-webkit-keyframes load7 {
    0%,
    80%,
    100% {
      box-shadow: 0 2.5em 0 -1.3em;
    }
    40% {
      box-shadow: 0 2.5em 0 0;
    }
  }
  @keyframes load7 {
    0%,
    80%,
    100% {
      box-shadow: 0 2.5em 0 -1.3em;
    }
    40% {
      box-shadow: 0 2.5em 0 0;
    }
  }


  .home-carousel {
    position: relative;
    height: 412px;

    @media ($tablet-wide) {
      height: 512px;
    }

    @media ($desktop) {
      height: 712px;
    }

    .carousel-banner {
      position: absolute;
      z-index: 100;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;

      @media ($mobile-narrow) {
        width: auto;
      }

      @media ($tablet-wide) {
        display: none !important;
      }

      p {
        text-align: center;
        background: rgba(255,255,255,0.9);
        color: black;
        padding: 22px;
        font-weight: 600;

        @media ($mobile-narrow) {
          border-radius: 12px;
          box-shadow: 0 4px 14px rgba(0,0,0,0.5);
        }

        @media ($tablet) {
          br {
            display: none;
          }
        }
      }
    }

    .bg {
      position: absolute;
      overflow: hidden;
      top: 0;
      left: 0;
      width: 100%;
      height: 412px;
      border-radius: $radius;
      background-size: cover;
      background-position: center;
      will-change: opacity;
      z-index: 0;

      @media ($tablet-wide) {
        border-radius: $radius-medium;
        height: 512px;
      }

      @media ($desktop) {
        height: 712px;
      }
    }
  }

  .box {

    &.gp-has-background-circles {
      color: $black;
      box-shadow: none;
      background-image: url('img/gp_bg_dots_mobile@2x.png');
      background-repeat: repeat-y;
      background-size: contain;
      background-position: top center;
      @media ($tablet) {
        background-image: url('img/gp_bg_dots_tablet@2x.png');
      }
      @media ($desktop) {
        background-image: url('img/gp_bg_dots_desktop@2x.png');
      }

      figure {
        margin: 30px 0;

        max-width: 350px;
        align-self: center;

        @media ($desktop) {
          margin: 0;
        }
      }

      .gp-content {
        hr {
          border-top: 1px solid $border-color;
          margin: 0 0 40px 0;
        }

        > p, div.p {
          margin-bottom: 40px;

          .gp-check-marks {
            max-width: 35px;
            margin: 6px 12px 6px 0;
            float: left;
          }

          .gp-progress-marks {
            position: relative;
            margin: 6px 12px 6px 0;
            float: left;
          }

          button {
            border: 0 none;
            background: none;
            color: $green;
            font-size: 1.6rem;
            font-weight: 600;
            cursor: pointer;

            &:hover,
            &:focus {
              color: darken($green, 10%);
            }
          }
        }

        div.gp-ambassadors {
          max-height: 0;
          height: auto;
          overflow: hidden;
          margin-top: 12px;
          transition: max-height 0.25s ease-out;

          &.open {
            max-height: 500px;
            transition: max-height 0.25s ease-in;
          }
        }

        div.box {
          margin: 0 !important;
          background-color: #f8f8f8;
          height: auto;
          max-height: 500px;

          p {
            margin-bottom: 0;

            strong {
              opacity: 0.6;
            }
          }
        }
      }
    }

    &.gp-contact-section {
      color: $black;
      box-shadow: none;
      margin: 0;
      padding: 0;

      .gp-contact-container {
        align-content: center;
        overflow: hidden;

        .gp-contact-circle {
          background: linear-gradient(180deg, #f1f1f1 0, rgba(238, 254, 243, 0) 100%) top center;
          width: 480px;
          height: 480px;
          padding: 50px;
          border-radius: 50%;
          text-align: center;
          align-self: center;

          @media ($super-small-mobile) {
            background: none;
            padding: 20px;
            width: auto;
            height: auto;
          }

          @media ('min-width: 767px') {
            padding: 70px;
            width: 550px;
            height: 550px;
          }

          form {
            display: flex;
            flex-direction: column;
            align-items: center;

            h2 {
              font-size: 2.2em;
              font-weight: 500;
            }

            .gp-form-fields {
              display: flex;
              max-width: 310px;

              &.single-lines {
                flex-direction: column;
              }

              input[type="text"], textarea {
                border: 1px solid $black;
                border-radius: 6px;
                background: transparent;
                //width: 280px;
                height: 37px;
                outline: none;
                padding: 0 12px;
                font-size: 16px;

                &.error {
                  border-color: $pink;
                }
              }

              textarea {
                height: 100px;
                padding: 12px;
              }

              span {
                font-size: 12px;
              }

              .checkbox-label {
                font-size: 1.2rem;
                font-weight: 400;
                display: flex;
                justify-content: center;
                align-content: center;
                align-items: center;
                margin-top: 10px;

                input[type="checkbox"] {
                  width: 20px;
                  height: 20px;
                  margin-right: 3px;
                }
              }

              p.is-error-text {
                margin: 2px 0 0;
              }

              button {
                border-radius: 30px;
                min-width: 130px;
                margin-top: 20px;
              }
            }
          }
        }

        img.contact-images-circles {
          align-self: center;
          max-width: 120%;
          margin-top: 0;

          @media ($tablet) {
            display: none;
          }
        }

        @media ($tablet) {
          background-image: url('img/gp_sign_up_bubbles@2x.png');
          background-repeat: no-repeat;
          background-size: contain;
          background-position: top center;

          @media ($desktop) {
            background-image: url('img/gp_sign_up_bubbles_desktop.png');
          }
        }
      }
    }
  }

}
