// Add class .is-clipped to <html> or <body> tag to hide page overflow when modal is open

.modal {
  @include overlay;
  align-items: center;
  display: none;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  position: fixed;
  z-index: 2000;

  &.is-active {
    display: flex;
  }

  .modal-background {
    @include overlay;
    background-color: rgba(#000000, 0.86);
  }

  .modal-close {
    @include delete;
    background: none;
    height: 40px;
    max-height: 40px;
    width: 40px;
    max-width: 40px;
    position: absolute;
    right: 2rem;
    top: 2rem;
    transition: transform 0.2s ease-in-out;

    &:before,
    &:after {
      background-color: $gray;
    }

    &:hover,
    &:focus {
      background: none;

      &:before,
      &:after {
        background-color: darken($gray, 15%);
      }
    }

    &:active {
      &:before,
      &:after {
        background-color: darken($gray, 20%);
      }
    }
  }

  &.is-small {
    .modal-content {
      font-size: 1.4rem;

      @media ($tablet) {
        width: 470px;
      }
    }

    .modal-content-body,
    .modal-content-footer {
      max-width: 410px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .modal-content {
    margin: 0 20px;
    padding: 0 20px;
    max-height: calc(100vh - 120px);
    overflow: auto;
    position: relative;
    width: 100%;

    @media ($tablet) {
      margin: 0 auto;
      max-height: calc(100vh - 40px);
      width: 640px;
    }

    > .box.modal-box {
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 4rem;
    }

    .modal-content-body,
    .modal-content-footer {
      padding-left: ($gap / 2);
      padding-right: ($gap / 2);

      @media ($mobile-narrow) {
        padding-left: 2rem;
        padding-right: 2rem;
      }

      @media ($tablet) {
        padding-left: 3.5rem;
        padding-right: 3.5rem;
      }

      @media ($tablet-wide) {
        padding-left: 4rem;
        padding-right: 4rem;
      }

      @media ($desktop) {
        padding-left: 6rem;
        padding-right: 6rem;
      }
    }

    .modal-content-body {
      padding-bottom: 2rem;
    }

    .modal-content-footer {
      padding-top: 4rem;
      border-top: 1px solid $border-color;
      text-align: center;
      font-size: 1.4rem;

      p {
        margin: 0 auto !important;
      }
    }

    .modal-title {
      margin: 0 auto 3rem;
      text-align: center;
      font-weight: 700;
      font-size: 3rem;

      @media ($tablet) {
        font-size: 4rem;
      }
    }

    p {
      &:last-of-type {
        margin-bottom: 3rem;
      }
    }
  }
}
