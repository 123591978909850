@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;0,700;1,400;1,600;1,700&display=swap');

// Colors
$green: #42b067;
$pink: #e35780;
$warning: rgba(255, 153, 51, 0.2); // #ff9933
$gray: #6d8190;
$border-color: rgba($gray, 0.15);
$black: #19242f;
$white: #ffffff;
$georgiaPlacesBlack: #333333;

// Button colors
// class name (is-green), text color, background color
$button-colors: (
  'green': (
    $white,
    $green,
  ),
  'white': (
    $black,
    $white,
  ),
  'black': (
    $white,
    $black,
  )
);

// Text colors
// class name (has-text-green)
$text-colors: (
  'green': $green,
  'pink': $pink,
  'white': $white,
  'black': $black,
);

// Gradients
$gradient-green: linear-gradient(180deg, #eefef3 0, rgba(238, 254, 243, 0) 100%);
$gradient-dark: linear-gradient(180deg, #f8f8f8 0, rgba(238, 254, 243, 0) 100%);
$gradient-peach: linear-gradient(215.98deg, #d15a93 9.74%, #e7bf54 86.02%);
$gradient-peach-reversed: linear-gradient(330.98deg, #d15a93 9.74%, #e7bf54 86.02%);
//$gradient-peach-reversed: linear-gradient(225.98deg, #e7bf54 9.74%, #d15a93 86.02%);
$gradient-peach-dark: linear-gradient(215.98deg, darken(#d15a93, 0.1) 9.74%, darken(#e7bf54, 0.1) 86.02%);
$gradient-peach-opacity: linear-gradient(215.98deg, rgba(#d15a93, 0.2) 9.74%, rgba(#e7bf54, 0.2) 86.02%);
// Breakpoints
$gap: 30px;

// All styles are mobile-first, then work their way up
// Mobile Narrow
$super-small-mobile: 'max-width: 320px';
$under-mobile-narrow: 'max-width: 418px';
$mobile-narrow: 'min-width: 419px';
// Tablet
$under-tablet: 'max-width: 768px';
$tablet: 'min-width: 769px';
// Tablet-Wide
$under-tablet-wide-width: 960px + (2 * $gap) - 1px;
$under-tablet-wide: 'max-width: #{$under-tablet-wide-width}';
$tablet-wide-width: 960px + (2 * $gap);
$tablet-wide: 'min-width: #{$tablet-wide-width}';
// Desktop
$under-desktop-width: 1200px + (2 * $gap) - 1px;
$under-desktop: 'max-width: #{$under-desktop-width}';
$desktop-width: 1200px + (2 * $gap);
$desktop: 'min-width: #{$desktop-width}';

$content-max-width: 1220px + (2 * $gap);

// Fonts
$family-sans-serif: BlinkMacSystemFont, -apple-system, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
  'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
$family-monospace: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
// Styled fonts (add custom to beginning)
$body-font: 'Poppins', $family-sans-serif;
$header-font: 'Poppins', $family-sans-serif;
$code-font: $family-monospace;

// Element properties
$radius: 18px;
$radius-small: $radius; // alias
$radius-medium: 30px;
$radius-large: 60px;
$radius-rounded: 290486px; // Rounded border-radius
$box-shadow: 0px 10px 40px rgba(25, 36, 47, 0.1);
$navbar-link-border-width: 3px;
$icon-size: 4.5rem;
